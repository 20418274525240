<template>
  <div v-if="!documents">
    <q-inner-loading
      :visible="!documents"
    >
      <q-spinner-dots
        size="60"
      />
    </q-inner-loading>
  </div>
  <div v-else-if="documents.length" class="layout-padding">
    <document-card v-for="document in documents" :key="document.url" :document="document" />
  </div>
  <div v-else>
    <m-empty-state
      icon="attach_file"
      style="margin: auto;"
    >
      {{ $t('no_documents_found') }}
    </m-empty-state>
  </div>
</template>

<script>
import { documents } from 'api/bookings'
import documentCard from './document-card'
export default {
  components: { documentCard },
  props: ['reference'],
  data () {
    return {
      documents: null
    }
  },
  created () {
    this.getDocuments()
  },
  methods: {
    getDocuments () {
      documents(this.$route.params.reference)
        .then(documents => {
          this.documents = documents.data
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.layout-padding
  @media (max-width: 1199px)
    padding 1em
</style>
