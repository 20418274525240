<template>
  <q-page class="content-around layout-padding">
    <q-inner-loading v-if="!booking" :visible="!booking">
      <q-spinner-dots size="100px" />
    </q-inner-loading>
    <template v-else>
      <q-list v-if="latest" class="bg-white q-mb-sm" bordered>
        <template v-if="$q.screen.gt.md">
          <q-item>
            <q-item-section>
              {{ $t('drt.booking_details.predicted_pickup_time') }}
            </q-item-section>
            <q-item-section side>
              {{ latest.pickup | date }}
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              {{ $t('drt.booking_details.predicted_dropoff_time') }}
            </q-item-section>
            <q-item-section side>
              {{ latest.dropoff | date }}
            </q-item-section>
          </q-item>
        </template>
        <template v-else>
          <q-item class="bg-grey-2">
            <q-item-section>
              <q-item-label>
                {{ $t('drt.booking_details.predicted_times') }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label caption>
                {{ $t('drt.booking_details.pickup', 0) }}
              </q-item-label>
              <q-item-label>
                {{ latest.pickup | date }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label caption>
                {{ $t('drt.booking_details.pickup', 1) }}
              </q-item-label>
              <q-item-label>
                {{ latest.dropoff | date }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>

      <q-list class="bg-grey-2" bordered separator>
        <q-expansion-item
          :group="$q.platform.is.mobile ? 'bookingDetails' : '1'"
          :label="$t('booking.information')"
          default-opened
        >
          <q-item
            v-for="(item, index) in booking.details"
            :key="`details-${index}`"
            class="bg-white"
          >
            <q-item-section>
              <q-item-label caption v-text="item.label" />
              <q-item-label v-text="item.text" />
            </q-item-section>
          </q-item>
        </q-expansion-item>
        <q-expansion-item
          v-if="booking.notes.data && booking.notes.data.length"
          :group="$q.platform.is.mobile ? 'bookingDetails' : '2'"
          :label="$t('additional_information')"
        >
          <m-editor-viewer v-for="note in booking.notes.data" :key="note.reference" class="bg-white" :content="note.body" />
        </q-expansion-item>

        <q-card-actions v-if="partner.slug === 'derbygo'">
          <q-btn
            flat
            @click="pushToAmend('amend')"
          >
            {{ $t('amend') }}
          </q-btn>
          <q-btn
            flat
            @click="pushToAmend('cancel')"
          >
            {{ $t('cancel') }}
          </q-btn>
        </q-card-actions>

        <q-card-actions v-else-if="!hasStarted() && booking.status !== 'CANCELLED'" align="right">
          <q-btn
            flat
            :to="{
              query: {
                type:'amendment',
                booking: booking.reference
              },
              hash: '/concierge'
            }"
          >
            {{ $t('amend') }}
          </q-btn>
          <q-btn
            flat
            @click="$store.dispatch('entities/bookings/cancel', booking.reference)"
          >
            {{ $t('cancel') }}
          </q-btn>
        </q-card-actions>
      </q-list>

      <q-footer v-if="$q.platform.is.mobile" class="bg-white footer">
        <div class=" totalPrice row justify-between">
          <div class="col-6">
            {{ $t('total_cost') }}:
          </div>
          <div
            class="col-6 text-bold text-right"
            @click.stop="$router.push(`/bookings/${booking.reference}/breakdown`)"
          >
            {{ booking.price.total.text }} <q-icon name="fas fa-chevron-right" color="primary" />
          </div>
        </div>
        <q-btn
          v-if="['gohi'].includes(partner.slug)"
          class="full-width"
          dense
          color="primary"
          outline :label="$t(['bus', 'rail'].includes(booking.type) ? 'booking.view_tickets' : 'booking.view_attachments')"
          no-caps
          @click="booking.type === 'bus' ? $router.push('/tickets') : $router.push(`/bookings/${booking.reference}/documents`)"
        />
        <q-btn
          v-else
          class="full-width"
          dense
          color="primary"
          outline :label="$t('booking.view_attachments')"
          no-caps
          @click="$router.push(`/bookings/${booking.reference}/documents`)"
        />
        <q-btn
          class="full-width"
          dense
          color="primary"
          :label="$t('booking.return_to_journey')"
          no-caps
          @click="$router.go(-1)"
        />
      </q-footer>
    </template>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import MEditorViewer from 'components/MEditorViewer'

export default {
  components: {
    MEditorViewer
  },
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: {
    booking: Object
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    journey () {
      return this.booking.journey.data
    },
    latest () {
      const latest = this.booking.latest.data[0]
      if (!latest) return null
      return {
        dropoff: latest.predicted_dropoff_time,
        pickup: latest.predicted_pickup_time
      }
    }
  },
  methods: {
    hasStarted () {
      const today = date.newDate()

      const untilStart = date.getDateDiff(today, this.booking.starts_at.date)

      return untilStart > 0
    },
    pushToAmend (type) {
      this.$router.push({
        hash: `/#${type}`,
        params: {
          booking: this.booking.reference,
          content: this.booking.type
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  @media (max-width: 1199px)
    padding 1em
.content-around
  position relative
  .q-inner-loading
    background: transparent
    height: 200px
.infoCard
  background-color white
  box-shadow none
.footer
  padding 16px
  padding-top 4px
  border 1px solid rgba(black, 0.125)
  *
    margin-top 8px
    margin-bottom 8px
</style>
