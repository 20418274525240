<template>
  <q-page class="layout-padding q-pa-md">
    <h6 class="col-12 text-uppercase text-faded q-pb-md">
      {{ $t('recent_bookings') }}
    </h6>
    <div class="q-col-gutter-md">
      <m-booking-card-list />
    </div>
  </q-page>
</template>

<script type="text/javascript">
export default {
  name: 'Bookings'
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
.text-uppercase
  text-transform text-uppercase
</style>
