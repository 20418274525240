<template>
  <q-menu v-bind="$attrs">
    <q-list>
      <q-item v-close-popup clickable @click="viewBooking">
        <q-item-section>View Booking</q-item-section>
      </q-item>
      <q-item v-close-popup clickable @click="viewInvoices">
        <q-item-section>View Invoices</q-item-section>
      </q-item>
      <q-item v-close-popup clickable @click="viewDocuments">
        <q-item-section>View Documents</q-item-section>
      </q-item>
      <q-separator />
      <q-item v-if="hasPermission('can.view.labels')" clickable>
        <q-item-section>Label as</q-item-section>
        <q-item-section side>
          <q-icon name="arrow_right" />
        </q-item-section>
        <m-attach-label-menu
          resource="bookings"
          :resource-reference="item.reference"
          :labels="labels"
          :selected-labels="selectedLabels"
        />
      </q-item>
    </q-list>
  </q-menu>
</template>
<script>
import authentication from 'mixins/authentication'
export default {
  mixins: [authentication],
  props: ['item', 'selectedLabels', 'labels'],
  methods: {
    viewInvoices () {
      this.$router.push({ hash: '/booking/invoices', query: { booking: this.item.reference } })
    },
    viewDocuments () {
      this.$router.push({ hash: '/booking/documents', query: { booking: this.item.reference } })
    },
    viewBooking () {
      this.$router.push({ hash: '/booking', query: { booking: this.item.reference } })
    }
  }
}
</script>
