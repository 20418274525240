<template>
  <div class="index-list layout-padding">
    <m-invoices
      :load="load"
      :reset-items="() => {$store.dispatch('entities/invoices/deleteAll')}"
      :show-filters="false"
      height="calc(100vh - 450px)"
    />
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('entities/invoices/deleteAll')
  },
  methods: {
    async load (filters) {
      return this.$store.dispatch('entities/invoices/loadBookingInvoices', { bookingReference: this.$route.params.reference })
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  padding 3rem 3rem 0 3rem
  height calc(100vh - 600px)
.row-item
  width 100%
  margin auto
</style>
