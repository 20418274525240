<template>
  <q-card class="no-shadow item-card" @click="openBooking">
    <q-item v-if="item.reference !== 'LOADING'">
      <context-menu
        :item="item"
        :labels="labels"
        context-menu
        touch-position
        :selected-labels="attachedLabels"
      />
      <q-item-section>
        <q-chip class="bg-transparent">
          <m-icons-avatar
            :type="item.type !== 'other' ? item.type : 'concierge'"
            :title="item.type"
            :inverted="true"
          />
          &nbsp;{{ item.reference }}
        </q-chip>
      </q-item-section>
      <q-item-section>
        <q-item-label class="no-wrap ellipsis">
          {{ item.name }}
          <q-tooltip>
            {{ item.name }}
          </q-tooltip>
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.booked_at | date }}</q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.starts_at | date }}</q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label v-if="item.price">
          {{ item.price.total ? item.price.total.text : null }}
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label v-if="item.user">
          {{ passengerName }}
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label v-if="item.requester.data">
          {{ item.requester.data.display_name }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-item v-else>
      <q-item-section side>
        <q-item-label>LOADING</q-item-label>
      </q-item-section>
      <q-item-section>
        <q-linear-progress query color="primary" style="height: 20px" />
      </q-item-section>
    </q-item>
  </q-card>
</template>
<script>
import contextMenu from './context-menu'
import date from 'utils/date-time'
import Label from 'store/models/label.js'
export default {
  components: { contextMenu },
  filters: {
    date (data) {
      return data ? date.toShortDate(data) : null
    }
  },
  props: ['item', 'index'],
  computed: {
    labels () {
      return Label.all()
    },
    attachedLabels () {
      return this.item.labels.map(label => label.name)
    },
    passengerName () {
      if (this.item.passenger_name !== null) {
        return this.item.passenger_name
      } else if (this.item.type === 'onwardtravel') {
        return this.$t('gdpr.removed')
      }

      return this.item.user.data.first_name + ' ' + this.item.user.data.last_name
    }
  },
  methods: {
    openBooking () {
      this.$router.push({ hash: `/booking`, query: { booking: this.item.reference } })
    }
  }
}
</script>
<style lang="stylus" scoped>
.card-container
  height 62px
  overflow hidden
.q-card
  height 52px
  outline 0.5px solid #e9e9e9
  display inline-block
  width calc(100% - 15px)
.col
  vertical-align bottom

.item-card
  background white
  font-size 15px
  line-height 18px
.hover .item-card
  background #e1e1e1
  cursor pointer
</style>
